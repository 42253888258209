import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { addDays, addHours, addMinutes, format } from 'date-fns';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';

function Scheduling() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [dates, setDates] = useState([]);
  const [hours, setHours] = useState([]);
  const [dateFormatted, setDateFormatted] = useState('');
  const [hourFormatted, setHourFormatted] = useState('');
  const [minutesFormatted, setMinutesFormatted] = useState('');

  const { company } = useSelector(state => state.data);
  const { scheduling } = useSelector(state => state.order.order);

  const handleDateClick = date => {
    setDateFormatted(date.dateFormatted);
    setHourFormatted('');
    setMinutesFormatted('');

    dispatch({
      type: '@order/SCHEDULING',
      payload: {
        scheduling: true,
        day: date.dateFormatted,
        hour: '',
        minutes: '',
      },
    });
  };

  const handleHourClick = hour => {
    setHourFormatted(hour.hourFormatted);
    setMinutesFormatted(hour.minutesFormatted);

    dispatch({
      type: '@order/SCHEDULING',
      payload: {
        scheduling: true,
        day: dateFormatted,
        hour: hour.hourFormatted,
        minutes: hour.minutesFormatted,
      },
    });
  };

  const handleChange = event => {
    const { checked } = event.target;

    if (checked) {
      handleDateClick(dates[0]);
    } else {
      dispatch({
        type: '@order/SCHEDULING',
        payload: {
          scheduling: false,
          day: '',
          hour: '',
          minutes: '',
        },
      });

      setDateFormatted('');
      setHourFormatted('');
      setMinutesFormatted('');
    }
  };

  useEffect(() => {
    const officeHour = company.journey
      .split('-')
      .map(item => Number(item.trim().split(':')[0]));

    const currentDates = [];
    const currentDate = new Date();

    const startDate =
      currentDate.getHours() <= officeHour[1] && company.enabled === 1
        ? currentDate
        : addDays(currentDate, 1);

    while (currentDates.length <= 30) {
      const date = addDays(startDate, currentDates.length);
      currentDates.push({
        dateFormatted: format(date, 'yyyy-MM-dd'),
        dateFormattedDisplay: format(date, 'dd/MM'),
      });
    }

    setDates(currentDates);
  }, [company.journey, company.enabled]);

  useEffect(() => {
    if (dateFormatted.length !== 0) {
      const officeHour = company.journey
        .split('-')
        .map(item => Number(item.trim().split(':')[0]));

      const currentDate = new Date();

      let hour = 0;

      if (
        dateFormatted === format(currentDate, 'yyyy-MM-dd') &&
        officeHour[0] <= currentDate.getHours()
      ) {
        officeHour[0] = currentDate.getHours();
      } else if (dateFormatted === format(currentDate, 'yyyy-MM-dd')) {
        hour = company.company_id === 182 ? 0 : 90;
      }

      if (
        dateFormatted === format(currentDate, 'yyyy-MM-dd') &&
        officeHour[0] === currentDate.getHours() &&
        currentDate.getMinutes() <= 15
      ) {
        hour = company.company_id === 182 ? 30 : 105;
      } else if (
        dateFormatted === format(currentDate, 'yyyy-MM-dd') &&
        officeHour[0] === currentDate.getHours() &&
        currentDate.getMinutes() <= 30
      ) {
        hour = company.company_id === 182 ? 45 : 120;
      } else if (
        dateFormatted === format(currentDate, 'yyyy-MM-dd') &&
        officeHour[0] === currentDate.getHours() &&
        currentDate.getMinutes() <= 45
      ) {
        hour = company.company_id === 182 ? 60 : 135;
      } else if (
        dateFormatted === format(currentDate, 'yyyy-MM-dd') &&
        officeHour[0] === currentDate.getHours() &&
        currentDate.getMinutes() <= 60
      ) {
        hour = company.company_id === 182 ? 75 : 150;
      }

      const startOfficeHour = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate(),
        hour >= 60 ? officeHour[0] + 1 : officeHour[0],
        hour >= 60 ? hour - 60 : hour,
        0
      );

      const currentHours = [];
      for (let i = officeHour[0]; i < officeHour[1]; i++) {
        const currentHour = addHours(startOfficeHour, i - officeHour[0]);

        for (let j = 0; j < 60; j += 15) {
          const minutes = addMinutes(currentHour, j);
          if (minutes.getHours() > 0 && minutes.getHours() < officeHour[1]) {
            currentHours.push({
              hourFormatted: format(minutes, 'HH'),
              hourFormattedDisplay: format(minutes, 'HH:mm'),
              minutesFormatted: format(minutes, 'mm'),
            });
          }
        }
      }

      setHours(currentHours);
    }
  }, [company, dateFormatted]);

  useEffect(() => {
    if (dateFormatted.length !== 0) {
      const button = document.querySelector(`#button-${dateFormatted}`);

      if (button) {
        button.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [dateFormatted]);

  useEffect(() => {
    if (hourFormatted.length !== 0) {
      const button = document.querySelector(
        `#button-${hourFormatted}-${minutesFormatted}`
      );

      if (button) {
        button.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }
    }
  }, [hourFormatted, minutesFormatted]);

  return (
    <div className={classes.root}>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox onChange={handleChange} color="primary" />}
          label="Gostaria de agendar o pedido?"
        />
      </FormGroup>

      {scheduling && (
        <div className={classes.scheduling}>
          <div className={classes.content}>
            <span>Dia</span>
            <div className={classes.list}>
              {dates.map((date, index) => (
                <button
                  type="button"
                  id={`button-${date.dateFormatted}`}
                  key={`button`.concat(index)}
                  className={clsx(classes.button, {
                    [classes.buttonSelected]:
                      date.dateFormatted === dateFormatted,
                  })}
                  onClick={() => handleDateClick(date)}
                >
                  {`${date.dateFormattedDisplay}`}
                </button>
              ))}
            </div>
          </div>

          {hours.length >= 1 && (
            <div className={classes.content}>
              <span>Hora</span>
              <div className={classes.list}>
                {hours.map((hour, index) => (
                  <button
                    type="button"
                    id={`button-${hour.hourFormatted}-${hour.minutesFormatted}`}
                    key={`button`.concat(index)}
                    className={clsx(classes.button, {
                      [classes.buttonSelected]:
                        hour.hourFormatted === hourFormatted &&
                        hour.minutesFormatted === minutesFormatted,
                    })}
                    onClick={() => handleHourClick(hour)}
                  >
                    {`${hour.hourFormattedDisplay}`}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Scheduling;
