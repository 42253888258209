import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import useStyles from './styles';

function Item({ item, isHalfToHalf }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { product: detalhe } = useSelector(state => state.item);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    setProduct({ ...item });
  }, [item]);

  function handleRemove(pro) {
    dispatch({
      type: '@item/COMPLEMENT_REMOVE',
      data: {
        product: pro,
      },
    });
  }

  function handleAdd(pro) {
    dispatch({
      type: '@item/COMPLEMENT_ADD',
      data: {
        product: isHalfToHalf
          ? {
              ...pro,
              name: `1/2 ${pro.name}`,
            }
          : pro,
      },
    });

    const complement = detalhe.complements.find(
      f => f.complement_id === product.complement_id
    );

    if (complement && complement.selected + 1 === complement.max) {
      const index = detalhe.complements.findIndex(
        f => f.complement_id === complement.complement_id
      );

      if (index + 1 < detalhe.complements.length) {
        const id = detalhe.complements[index + 1].complement_id;
        const anchor = document.querySelector(`#complement${id}`);
        if (anchor) {
          anchor.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  }

  return (
    product && (
      <div className={classes.root}>
        <div className={classes.fields}>
          <span className={classes.fieldsName}>
            {isHalfToHalf
              ? `1/2 ${item.name.toLowerCase()}`
              : item.name.toLowerCase()}
          </span>
          <span>{item.description}</span>
          {item.price !== 0 && (
            <span className={classes.fieldsPrice}>
              {`+ ${item.formattedPrice}`}
            </span>
          )}
        </div>

        <div className={classes.actions}>
          {product.amount >= 1 && (
            <Fab
              size="small"
              aria-label="product-remove"
              onClick={() => handleRemove(product)}
            >
              <RemoveIcon />
            </Fab>
          )}

          {product.amount >= 1 && (
            <span className={classes.actionsAmount}>{product.amount}</span>
          )}

          <Fab
            size="small"
            aria-label="product-add"
            onClick={() => handleAdd(product)}
          >
            <AddIcon />
          </Fab>
        </div>
      </div>
    )
  );
}

export default Item;
