import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { formatPrice } from '../../utils/format';
import useStyles from './styles';

export default function OrderButton() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const count = useSelector(state => state.order.order.items.length);
  const total = formatPrice(useSelector(state => state.order.order.total));

  const handleOrder = () => {
    dispatch({ type: '@order/SHOW' });
  };

  return (
    <Button color="primary" className={classes.button} onClick={handleOrder}>
      <Typography className={classes.title}>{count}</Typography>
      <Typography className={classes.title}>Ver pedido</Typography>
      <Typography className={classes.title}>{total}</Typography>
    </Button>
  );
}
