export default function payments(
  state = {
    modalPayments: false,
  },
  action
) {
  switch (action.type) {
    case '@payments/MODAL': {
      return {
        ...state,
        modalPayments: !state.modalPayments,
      };
    }

    case '@order/PAYMENT': {
      return {
        ...state,
        modalPayments: false,
      };
    }

    default:
      return state;
  }
}
