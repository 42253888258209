/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useSnackbar } from 'notistack';
import MaskService from 'react-masked-text/src/mask-service';
import { MoneyMask } from 'react-masked-text/src/masks/money.mask';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from '../../../utils/format';
import useStyles from './styles';

const paymentValueDefault = { value: '0,00' };

function Confirmation({ modalPayment, setModalPayment, payment }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [paymentValue, setPaymentValue] = useState(paymentValueDefault);
  const { company } = useSelector(state => state.data);
  const { formattedTotal, total } = useSelector(state => {
    const { order } = state.order;

    return {
      total: order ? order.total : 0,
      formattedTotal: formatPrice(order ? order.total : 0),
    };
  });

  useEffect(() => {
    if (modalPayment) setPaymentValue(paymentValueDefault);
  }, [modalPayment]);

  const handleClose = () => {
    setModalPayment(false);
  };

  const handleConfirm = () => {
    const moneyMaskFormatting = new MoneyMask();
    const value = moneyMaskFormatting.getRawValue(paymentValue.value);

    if (value > 0 && value <= total) {
      enqueueSnackbar(
        `Você deve pedir troco para um valor maior do que ${formattedTotal}`,
        { variant: 'error' }
      );

      setPaymentValue({
        value: paymentValue.value,
        error: true,
      });

      return;
    }

    setModalPayment(false);

    dispatch({
      type: '@order/PAYMENT',
      payment: {
        id: payment,
        value,
        formattedValue: formatPrice(value),
      },
    });
  };

  return (
    <Drawer anchor="bottom" open={modalPayment} onClose={handleClose}>
      <div className={classes.root}>
        <span className={classes.title}>pagamento</span>

        {payment === 1 && (
          <>
            <div className={classes.item}>
              <div>
                <MonetizationOnIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagar na entrega</small>
                  </span>
                  <span>
                    <strong>Dinheiro</strong>
                  </span>
                </div>
              </div>
              <span onClick={handleClose}>
                <small>Alterar</small>
              </span>
            </div>

            <span className={classes.troco}>Troco para quanto?</span>

            <TextField
              error={paymentValue.error}
              id="payment-value"
              InputProps={{
                fullWidth: true,
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              value={paymentValue.value}
              onChange={event => {
                const money = MaskService.toMask('money', event.target.value, {
                  unit: '',
                  separator: ',',
                  delimiter: '.',
                });
                setPaymentValue({ value: money });
              }}
            />
          </>
        )}

        {payment === 2 && (
          <div className={classes.item}>
            <div>
              <CreditCardIcon fontSize="small" />
              <div>
                <span>
                  <small>Pagar na entrega</small>
                </span>
                <span>
                  <strong>Crédito</strong>
                </span>
              </div>
            </div>
            <span onClick={handleClose}>
              <small>Alterar</small>
            </span>
          </div>
        )}

        {payment === 3 && (
          <div className={classes.item}>
            <div>
              <CreditCardIcon fontSize="small" />
              <div>
                <span>
                  <small>Pagar na entrega</small>
                </span>
                <span>
                  <strong>Débito</strong>
                </span>
              </div>
            </div>
            <span onClick={handleClose}>
              <small>Alterar</small>
            </span>
          </div>
        )}

        {payment === 4 && (
          <div className={classes.item}>
            <AccountBalanceIcon fontSize="small" />
            <div>
              <div>
                <span>
                  <small>Pagar agora</small>
                </span>
                <span>
                  <small>{`MATILDE TEREZINHA DE OLIVEIRA AMARILHA`}</small>
                </span>
                <span>
                  <strong>{`Pix (67) 99220-8097`}</strong>
                </span>
              </div>
            </div>
            <span onClick={handleClose}>
              <small>Alterar</small>
            </span>
          </div>
        )}

        <button
          type="button"
          className={classes.button}
          onClick={handleConfirm}
        >
          confirmar pagamento
        </button>
      </div>
    </Drawer>
  );
}

export default Confirmation;
