import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import ProductWithoutImage from '../../assets/ProductWithoutImage.png';
import useStyles from './styles';
import { formatPrice } from '../../utils/format';
import Complement from '../../components/Complement';
import Header from '../../components/Header';

const keyDefault = '';
const complementsDefault = [];
const amountDefault = 0;
const commentDefault = '';
const subtotalDefault = 0;
const formattedSubtotalDefault = 'R$ 0,00';

export default function Detail() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const dispatch = useDispatch();

  const { modalDetail, product } = useSelector(state => state.item);
  const { order } = useSelector(state => state.order);
  const [key, setKey] = useState(keyDefault);
  const [complements, setComplements] = useState(complementsDefault);
  const [amount, setAmount] = useState(amountDefault);
  const [comment, setComment] = useState(commentDefault);
  const [subtotal, setSubtotal] = useState(subtotalDefault);
  const [formattedSubtotal, setFormattedSubtotal] = useState(
    formattedSubtotalDefault
  );

  useEffect(() => {
    if (product) {
      setComplements(product.complements);
      if (key === keyDefault || product.key !== key) {
        setKey(product.key);
        setAmount(product.amount);
        setComment(product.comment);
      }
    } else {
      setKey(keyDefault);
      setComplements(complementsDefault);
      setAmount(amountDefault);
      setComment(commentDefault);
    }
  }, [product, key]);

  useEffect(() => {
    if (order) {
      dispatch({ type: '@item/CANCEL' });
    }
  }, [order, dispatch]);

  const handleChangeComment = event => {
    setComment(event.target.value);
  };

  const handleRemoveAmount = () => {
    if (amount >= 2) {
      const sub = amount - 1;
      setAmount(sub);
    }
  };

  const handleAddAmount = () => {
    const add = amount + 1;
    setAmount(add);
  };

  useEffect(() => {
    if (product && amount >= 1) {
      // const optional = product.complements.filter(f => f.required === 'N');
      const optional = product.complements;
      const optionalPrices = optional.reduce((a, complement) => {
        const complementPrices = complement.items.reduce((b, item) => {
          return b + item.amount * item.price;
        }, 0);

        return a + complementPrices;
      }, 0);
      const productPrice = product.price === 0.01 ? 0 : product.price;
      const sub = amount * (optionalPrices + productPrice);
      setSubtotal(sub);
      setFormattedSubtotal(formatPrice(sub));
    }
  }, [amount, product]);

  const handleClose = () => {
    dispatch({ type: '@item/CANCEL' });
  };

  const handleConfirm = () => {
    const required = product.complements.find(
      f => f.required === 'S' && f.selected < f.max
    );

    if (required) {
      enqueueSnackbar(
        `É preciso escolher todos os itens obrigatorios antes de adicionar.`,
        { variant: 'default' }
      );
      return;
    }

    const item = {
      ...product,
      amount,
      comment,
      subtotal,
    };

    dispatch({ type: '@order/ADD_ITEM', data: item });
  };

  return product ? (
    <Dialog fullScreen={fullScreen} open={modalDetail} className={classes.root}>
      <Header handleClose={handleClose} title="detalhes do item" />
      <div className={classes.content}>
        <div className={classes.media}>
          <img
            className={classes.img}
            src={product.url ? product.url : ProductWithoutImage}
            alt={product.name}
          />
        </div>

        <div className={classes.fields}>
          <span className={classes.fieldName}>{product.name}</span>
          <span className={classes.fieldDescription}>
            {product.descriptionFull}
          </span>
          {product.price !== 0.01 && (
            <span className={classes.fieldPrice}>{product.formattedPrice}</span>
          )}
        </div>

        {complements.map(complement => (
          <Complement key={complement.complement_id} complement={complement} />
        ))}

        <div className={classes.noteContainer}>
          <div className={classes.label}>
            <SpeakerNotesIcon className={classes.icon} />
            <span className={classes.text}>Alguma observação?</span>
            <span>{`${comment.length}/100`}</span>
          </div>
          <textarea
            className={classes.note}
            value={comment}
            onChange={handleChangeComment}
            maxLength={100}
            placeholder="Ex: Tirar a cebola, maionese à parte, ponto da carne etc"
          />
        </div>

        <div className={classes.actions}>
          <div className={classes.amountContainer}>
            <Fab
              size="small"
              aria-label="product-remove"
              onClick={handleRemoveAmount}
            >
              <RemoveIcon />
            </Fab>

            <span className={classes.amount}>{amount}</span>

            <Fab
              size="small"
              aria-label="product-add"
              onClick={handleAddAmount}
            >
              <AddIcon />
            </Fab>
          </div>

          <div className={classes.confirmContainer}>
            <Button className={classes.confirmButton} onClick={handleConfirm}>
              <span>adicionar</span>
              <span>{formattedSubtotal}</span>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  ) : null;
}
