/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Divider from '@material-ui/core/Divider';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreVert';
import ProductWithoutImage from '../../../assets/ProductWithoutImage.png';

import { formatPrice } from '../../../utils/format';

import useStyles from './styles';

function Items() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [modalMenu, setModalMenu] = useState();
  const [item, setItem] = useState({});

  function selecteds(complements) {
    const data = [];
    complements.forEach(complement => {
      complement.items.forEach(complementItem => {
        if (complementItem.amount >= 1) {
          data.push(
            complement.complement_id === 2251
              ? {
                  ...complementItem,
                  name: `1/2 ${complementItem.name}`,
                }
              : complementItem
          );
        }
      });
    });

    return data;
  }

  const items = useSelector(state => {
    const { order } = state.order;
    if (order && order.items) {
      return order.items.map(orderItem => {
        return {
          ...orderItem,
          formattedSubtotal: formatPrice(orderItem.subtotal),
          selectedComplements: selecteds(
            orderItem.complements,
            orderItem.category_id
          ),
        };
      });
    }

    return [];
  });

  function handleClose() {
    dispatch({ type: '@order/CLOSE' });
  }

  function handleMenu(product) {
    setItem(product);
    setModalMenu(true);
  }

  function handleItem(product) {
    setModalMenu(false);

    dispatch({
      type: '@item/SELECTED',
      data: {
        product,
      },
    });
  }

  function handleRemoveItem(product) {
    setModalMenu(false);

    dispatch({
      type: '@order/REMOVE_ITEM',
      key: product.key,
    });
  }

  return items ? (
    <>
      <div className={classes.root}>
        {items.map(product => (
          <div
            key={JSON.stringify(product.key + product.product_id)}
            className={classes.item}
          >
            <div className={classes.body} onClick={() => handleItem(product)}>
              <div className={classes.defaults}>
                <div className={classes.media}>
                  <img
                    className={classes.img}
                    src={product.url ? product.url : ProductWithoutImage}
                    alt={product.name}
                  />
                </div>
                <div className={classes.data}>
                  <span className={classes.span}>{`${product.amount}x`}</span>
                  <div className={classes.fields}>
                    <span className={classes.span}>{product.name}</span>
                  </div>
                  <div className={classes.price}>
                    <span>{product.formattedSubtotal}</span>
                  </div>
                </div>
              </div>

              {product.selectedComplements.length >= 1 && (
                <ul className={classes.complements}>
                  {product.selectedComplements &&
                    product.selectedComplements.map(complement => (
                      <li
                        key={JSON.stringify(product.key + complement.item_id)}
                      >
                        {`${complement.amount}x ${complement.name}`}
                      </li>
                    ))}
                </ul>
              )}

              {product.comment !== '' && (
                <div className={classes.comment}>
                  <SpeakerNotesIcon className={classes.icon} />
                  <span>{product.comment}</span>
                </div>
              )}
            </div>
            <div className={classes.menu}>
              <IconButton onClick={() => handleMenu(product)}>
                <MoreIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.goback} onClick={handleClose}>
        Adicionar mais itens
      </div>
      <Drawer
        anchor="bottom"
        open={modalMenu}
        onClose={() => setModalMenu(false)}
        variant="temporary"
      >
        <List>
          <ListItem>
            <ListItemText primary={item.name} />
          </ListItem>

          <Divider />

          <ListItem button onClick={() => handleItem(item)}>
            <ListItemIcon>
              <EditOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="EDITAR" />
          </ListItem>

          <ListItem button onClick={() => handleRemoveItem(item)}>
            <ListItemIcon>
              <DeleteOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="REMOVER" />
          </ListItem>
        </List>
      </Drawer>
    </>
  ) : null;
}

export default Items;
