import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Drawer, Typography } from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import useStyles from './styles';

export default function Success() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const customer = useSelector(state => {
    const { data } = state;
    const { client } = data;
    const { name } = client;
    return name.split(' ')[0].toLowerCase();
  });

  const { company } = useSelector(state => state.data);
  const { modalOrderSuccess, lastScheduledOrder } = useSelector(
    state => state.order
  );

  const handleClose = () => {
    dispatch({ type: '@order/SUCCESS' });
  };

  return (
    <Drawer anchor="bottom" open={modalOrderSuccess} onClose={handleClose}>
      <div className={classes.root}>
        <Typography variant="h3" component="h1">
          Sucesso!
        </Typography>

        <div className={classes.icon}>
          <DoneAllIcon fontSize="large" />
        </div>

        <Typography>
          <strong style={{ textTransform: 'capitalize' }}>{customer}</strong>,
          recebemos o seu pedido!
        </Typography>

        <Typography>
          Se necessário entraremos em contato através do seu número de telefone
          ou WhatsApp
        </Typography>

        {company.company_id === 197 && !lastScheduledOrder && (
          <Typography className={classes.warning}>
            Média de 40 a 50 minutos para entregar
          </Typography>
        )}

        {company.company_id === 156 && !lastScheduledOrder && (
          <Typography className={classes.warning}>
            Média de 90 minutos para entregar
          </Typography>
        )}

        <Button variant="contained" onClick={handleClose}>
          OK entendi
        </Button>
      </div>
    </Drawer>
  );
}
