import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';

import useStyles from './styles';

function Modality() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const delivery = 0;
  const drive = 1;

  const { company } = useSelector(state => state.data);
  const { addresses } = useSelector(state => state.addresses);
  const { modality } = useSelector(state => state.order.order);

  const handleChange = e => {
    const value = Number(e.target.value);
    dispatch({
      type: '@order/CHANGE_MODALITY',
      data: {
        addresses,
        modality: value,
      },
    });
  };

  return company ? (
    <div className={classes.root}>
      <span className={classes.title}>Entrega ou Retirada no local?</span>
      <ul className={classes.modality}>
        {company && company.delivery && (
          <li>
            <Radio
              checked={delivery === modality}
              onChange={handleChange}
              value={delivery}
              color="default"
              name="delivery"
            />
            {company.company_id === 156 ? (
              <span>Entrega ( tempo médio de 90min )</span>
            ) : (
              <span>Entrega</span>
            )}
          </li>
        )}

        {company && company.company_id !== 172 && (
          <li>
            <Radio
              checked={drive === modality}
              onChange={handleChange}
              value={drive}
              color="default"
              name="delivery"
            />
            <span>Retirada no local</span>
          </li>
        )}
      </ul>
    </div>
  ) : null;
}

export default Modality;
