/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import CreditCardIcon from '@material-ui/icons/CreditCard';

import useStyles from './styles';

function Payment() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { modality, payment } = useSelector(state => state.order.order);

  const handlePayments = () => {
    dispatch({ type: '@payments/MODAL' });
  };

  return modality <= 1 ? (
    <div className={classes.root}>
      <span className={classes.title}>Como vai pagar?</span>
      {payment && payment.id >= 1 ? (
        <>
          {payment.id === 1 && (
            <div className={classes.item}>
              <div>
                <MonetizationOnIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagar na entrega</small>
                  </span>
                  <span>
                    <strong>Dinheiro</strong>
                  </span>
                  {payment.value > 0 && (
                    <span>
                      <small>{`troco para: ${payment.formattedValue}`}</small>
                    </span>
                  )}
                </div>
              </div>
              <span onClick={handlePayments}>
                <small>Alterar</small>
              </span>
            </div>
          )}

          {payment.id === 2 && (
            <div className={classes.item}>
              <div>
                <CreditCardIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagar na entrega</small>
                  </span>
                  <span>
                    <strong>Crédito</strong>
                  </span>
                </div>
              </div>
              <span onClick={handlePayments}>
                <small>Alterar</small>
              </span>
            </div>
          )}

          {payment.id === 3 && (
            <div className={classes.item}>
              <div>
                <CreditCardIcon fontSize="small" />
                <div>
                  <span>
                    <small>Pagar na entrega</small>
                  </span>
                  <span>
                    <strong>Débito</strong>
                  </span>
                </div>
              </div>
              <span onClick={handlePayments}>
                <small>Alterar</small>
              </span>
            </div>
          )}

          {payment.id === 4 && (
            <div className={classes.item}>
              <AccountBalanceIcon fontSize="small" />
              <div>
                <div>
                  <span>
                    <small>Pagar agora</small>
                  </span>
                  <span>
                    <small>{`MATILDE TEREZINHA DE OLIVEIRA AMARILHA`}</small>
                  </span>
                  <span>
                    <strong>{`Pix (67) 99220-8097`}</strong>
                  </span>
                  <span
                    style={{
                      color: '#f44336',
                    }}
                  >
                    <strong>
                      <small>Enviar comprovante após o pedido</small>
                    </strong>
                  </span>
                </div>
              </div>
              <span onClick={handlePayments}>
                <small>Alterar</small>
              </span>
            </div>
          )}
        </>
      ) : (
        <button
          type="button"
          className={classes.button}
          onClick={handlePayments}
        >
          <span>Selecione a forma de pagamento</span>
        </button>
      )}
    </div>
  ) : null;
}

export default Payment;
