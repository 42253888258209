import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Container } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import Splash from '../../assets/splash.png';
import api from '../../services/api';
import Header from './Header';
import Unavailability from '../../components/Unavailability';
import Body from './Body';
import Footer from './Footer';
import Detail from '../Detail';
import OrderButton from '../../components/OrderButton';
import Order from '../Order';
import Addresses from '../Addresses';
import Payments from '../Payments';
import Success from '../Success';

import useStyles from './styles';

export default function Store({ match }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const { params } = match;

  const dispatch = useDispatch();
  const { company: main } = useSelector(state => state.data);
  const { order } = useSelector(state => state.order);

  useEffect(() => {
    async function getData() {
      try {
        let token;
        let defaultToken;

        if (process.env.REACT_APP_TOKEN) {
          token = process.env.REACT_APP_TOKEN;
          defaultToken = token;
        } else {
          token = params.token;
          defaultToken = 'DEFAULT';
        }

        const { index } = api(token || defaultToken);
        const data = await index();

        if (data) {
          const { company, categories, kms, districts } = data;

          dispatch({
            type: '@data/LOAD',
            data: {
              token,
              company,
              categories,
              kms,
              districts,
            },
          });

          dispatch({ type: '@addresses/LOAD', data: { token } });

          dispatch({ type: '@order/CREATE', data: { token } });
        }
      } catch (error) {
        dispatch({ type: '@data/LOADING' });

        enqueueSnackbar(
          'Houve uma falha ao tentar consultar os dados. Tente mais tarde',
          { variant: 'error' }
        );
      }
    }

    getData();
  }, [params, dispatch, enqueueSnackbar]);

  function beforeLloading() {
    return (
      <Container className={classes.splash}>
        <Avatar src={Splash} className={classes.avatar} />
      </Container>
    );
  }

  function afterLoading() {
    return (
      <Container className={classes.root}>
        <Header background={main.background} />
        <Unavailability />
        <Body />
        <Footer />
        {order && order.items && order.items.length >= 1 && <OrderButton />}
        <Detail />
        <Order />
        <Addresses />
        <Payments />
        <Success />
      </Container>
    );
  }

  return main && main.name ? afterLoading() : beforeLloading();
}
