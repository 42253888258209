export default function order(
  state = {
    order: null,
    modalOrder: false,
    modalOrderSuccess: false,
    lastScheduledOrder: false,
  },
  action
) {
  switch (action.type) {
    case '@order/CREATE': {
      if (state.order) return state;

      const { token } = action.data;
      const storageOrder = localStorage.getItem(token);
      const startOrder = storageOrder ? JSON.parse(storageOrder) : null;

      if (startOrder) {
        return {
          order: startOrder,
          modalOrder: false,
          modalOrderSuccess: false,
        };
      }

      const startingOrder = {
        token,
        items: [],
        subtotal: 0,
        delivery: 0,
        total: 0,
        modality: -1,
        payment: { id: 0, value: 0 },
        scheduling: false,
        day: '',
        hour: '',
        minutes: '',
      };

      localStorage.setItem(token, JSON.stringify(startingOrder));

      return {
        order: startingOrder,
        modalOrder: false,
        modalOrderSuccess: false,
      };
    }

    case '@order/CONFIRMED': {
      const { token } = action.data;

      const startingOrder = {
        token,
        items: [],
        subtotal: 0,
        delivery: 0,
        total: 0,
        modality: -1,
        payment: { id: 0, value: 0 },
        scheduling: false,
        day: '',
        hour: '',
        minutes: '',
      };

      localStorage.setItem(token, JSON.stringify(startingOrder));

      return {
        order: startingOrder,
        modalOrder: false,
        modalOrderSuccess: true,
        lastScheduledOrder: state.order.scheduling,
      };
    }

    case '@order/ADD_ITEM': {
      if (action.data) {
        const itemOld = state.order.items.find(
          item => item.key === action.data.key
        );

        if (itemOld) {
          const items = state.order.items.map(item => {
            if (item.key === action.data.key) return action.data;

            return item;
          });

          const subtotal = items.reduce((sum, item) => {
            return sum + item.subtotal;
          }, 0);

          const currentOrder = {
            ...state.order,
            items,
            subtotal,
            total: subtotal + state.order.delivery,
            payment: { id: 0, value: 0 },
          };

          localStorage.setItem(state.order.token, JSON.stringify(currentOrder));

          return { ...state, order: currentOrder };
        }

        const items = [...state.order.items, action.data];

        const subtotal = items.reduce((sum, item) => {
          return sum + item.subtotal;
        }, 0);

        const currentOrder = {
          ...state.order,
          items,
          subtotal,
          total: subtotal + state.order.delivery,
          payment: { id: 0, value: 0 },
        };

        localStorage.setItem(state.order.token, JSON.stringify(currentOrder));

        return { ...state, order: currentOrder };
      }

      return state;
    }

    case '@order/REMOVE_ITEM': {
      const items = state.order.items.filter(item => item.key !== action.key);

      const subtotal = items.reduce((sum, item) => {
        return sum + item.subtotal;
      }, 0);

      const currentOrder = {
        ...state.order,
        items,
        subtotal,
        total: subtotal + state.order.delivery,
        payment: { id: 0, value: 0 },
      };

      localStorage.setItem(state.order.token, JSON.stringify(currentOrder));

      return {
        ...state,
        order: currentOrder,
        modalOrder: items.length >= 1,
      };
    }

    case '@order/SET_ADDRESS': {
      if (
        state.order &&
        state.order.address &&
        state.order.address.key === action.address.key
      )
        return state;

      return {
        ...state,
        order: {
          ...state.order,
          address: action.address,
          delivery: action.address.delivery || 0,
          total: state.order.subtotal + (action.address.delivery || 0),
          payment: { id: 0, value: 0 },
        },
      };
    }

    case '@order/SHOW': {
      return {
        ...state,
        order: {
          ...state.order,
          scheduling: false,
          day: '',
          hour: '',
          minutes: '',
        },
        modalOrder: true,
      };
    }

    case '@order/CLOSE': {
      return {
        ...state,
        modalOrder: false,
      };
    }

    case '@order/PAYMENT': {
      return {
        ...state,
        order: {
          ...state.order,
          payment: action.payment,
        },
      };
    }

    case '@order/CHANGE_MODALITY': {
      if (action.data.modality === state.order.modality) {
        return state;
      }

      const { modality } = action.data;

      return {
        ...state,
        order: {
          ...state.order,
          address: null,
          delivery: 0,
          total: state.order.subtotal,
          modality,
          payment: { id: 0, value: 0 },
        },
      };
    }

    case '@order/SCHEDULING': {
      const { scheduling, day, hour, minutes } = action.payload;

      return {
        ...state,
        order: {
          ...state.order,
          scheduling,
          day,
          hour,
          minutes,
        },
      };
    }

    case '@order/SUCCESS': {
      return {
        ...state,
        modalOrderSuccess: false,
      };
    }

    default:
      return state;
  }
}
