import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@material-ui/core/Button';

import { useSnackbar } from 'notistack';
import { formatPrice } from '../../../utils/format';
import api from '../../../services/api';

import useStyles from './styles';

function Send() {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { company, loading, client } = useSelector(state => state.data);
  const { order: current } = useSelector(state => state.order);

  const handleConfirm = async () => {
    if (company.company_id === 182 && !current.scheduling) {
      enqueueSnackbar('Por favor, agende uma data e hora de entrega/retirada', {
        variant: 'error',
      });

      return;
    }

    if (company.enabled !== 1 && !current.scheduling) {
      enqueueSnackbar('Indisponível no momento', { variant: 'error' });

      return;
    }

    if (current.modality !== 0 && current.modality !== 1) {
      enqueueSnackbar('Entrega ou Retirada no local?', {
        variant: 'error',
      });

      return;
    }

    if (current.modality === 0 && current.subtotal < company.minimum_value) {
      const formattedMinimumValue = formatPrice(company.minimum_value);
      enqueueSnackbar(
        `O valor minimo para entrega é de ${formattedMinimumValue}`,
        { variant: 'error' }
      );

      return;
    }

    if (current.scheduling && current.day.length === 0) {
      enqueueSnackbar('Selecione o dia para agendar o pedido', {
        variant: 'error',
      });

      return;
    }

    if (current.scheduling && current.hour.length === 0) {
      enqueueSnackbar('Selecione a hora para agendar o pedido', {
        variant: 'error',
      });

      return;
    }

    if (current.modality === 0 && !current.address) {
      enqueueSnackbar('Selecione um endereço para entrega', {
        variant: 'error',
      });

      return;
    }

    if (current.payment.id === 0) {
      enqueueSnackbar('Selecione a forma de pagamento', { variant: 'error' });

      return;
    }

    if (client.name.length === 0) {
      enqueueSnackbar('Qual o seu nome?', { variant: 'error' });

      return;
    }

    if (client.phone.length <= 9) {
      enqueueSnackbar('Seu telefone deve ser preenchido corretamente', {
        variant: 'error',
      });

      return;
    }

    if (loading) return;

    dispatch({ type: '@data/LOADING' });

    const order = {
      version: '1.0.1',
      modality: current.modality,
      deliveryfee: current.modality === 0 ? current.delivery : 0,
      client:
        current.modality === 0
          ? { ...current.address, ...client }
          : { ...client },
      payment: (() => {
        const { id, value } = current.payment;
        return {
          id,
          value,
        };
      })(),
      items: current.items.map(item => {
        const subitems = [];
        item.complements.forEach(complement => {
          complement.items
            .filter(subitem => subitem.amount >= 1)
            .forEach(subitem => {
              const { product_id, name, amount, price } = subitem;
              const priceReal = () => {
                if (complement.complement_id === 2251) {
                  return price;
                }
                if (complement.required === 'S') {
                  return 0;
                }
                return price;
              };
              subitems.push({
                product_id,
                name: complement.complement_id === 2251 ? `1/2 ${name}` : name,
                amount,
                price: priceReal(),
                subtotal: priceReal() * amount,
              });
            });
        });

        const { product_id, name, amount, comment } = item;
        const totalsubitems = subitems.reduce((a, b) => {
          return a + b.subtotal;
        }, 0);

        return {
          product_id,
          name,
          amount,
          price: item.price,
          subtotal: item.price * amount,
          totalsubitems: totalsubitems * amount,
          total: item.price * amount + totalsubitems * amount,
          comment,
          subitems,
        };
      }),
      subtotal: current.subtotal,
      total: current.total,
      scheduling: null,
    };

    try {
      const service = api(current.token);

      const res = await service.orderset(order);

      if (res && res.success) {
        dispatch({
          type: '@order/CONFIRMED',
          data: { token: current.token },
        });

        dispatch({ type: '@data/LOADING' });

        return;
      }

      if (res && res.error) {
        dispatch({ type: '@data/LOADING' });
        enqueueSnackbar(res.error_message, { variant: 'error' });
        return;
      }

      dispatch({ type: '@data/LOADING' });

      enqueueSnackbar(
        'Houve uma falha interna e não foi possível salvar os dados do seu pedido. Tente mais tarde',
        { variant: 'error' }
      );
    } catch (error) {
      dispatch({ type: '@data/LOADING' });

      enqueueSnackbar(
        'Falha ao tentar enviar seu pedido. Verifique sua conexão com a internet',
        { variant: 'error' }
      );
    }
  };

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleConfirm}
      >
        pedir
      </Button>
    </div>
  );
}

export default Send;
