const api = token => {
  const baseURL = process.env.REACT_APP_API;

  const index = async function index() {
    const url = `${baseURL}/?t=${token}`;

    const res = await fetch(url, { method: 'GET' });

    const data = await res.json();

    return data;
  };

  const orderset = async function orderset(order) {
    const url = `${baseURL}/orderset/?t=${token}`;

    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(order),
    });

    const data = await res.json();

    return data || null;
  };

  return {
    index,
    orderset,
  };
};

export default api;
