import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  button: {
    position: 'fixed',
    bottom: '1rem',
    zIndex: 100,
    maxWidth: '1280px',
    width: 'calc(100% - 2rem)',
    height: '50px',
    marginLeft: '1rem',
    borderRadius: '1rem',

    display: 'flex',
    justifyContent: 'space-around',

    backgroundColor: '#F97F11',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#F97F11',
    },
  },
  title: {
    fontSize: 14,
  },
}));

export default useStyles;
