export default function addresses(
  state = {
    modalAddresses: false,
    addresses: [],
  },
  action
) {
  switch (action.type) {
    case '@addresses/LOAD': {
      if (state.addresses.length >= 1) return state;

      const storageAddresses = localStorage.getItem('addresses');
      const startAddresses = storageAddresses
        ? JSON.parse(storageAddresses)
        : [];

      return {
        ...state,
        addresses: [...startAddresses],
      };
    }

    case '@addresses/MODAL': {
      return {
        ...state,
        modalAddresses: !state.modalAddresses,
      };
    }

    case '@addresses/CREATE_SUCCESS': {
      const key = new Date().getTime();
      const currentAddresses = [...state.addresses, { ...action.address, key }];
      localStorage.setItem('addresses', JSON.stringify(currentAddresses));

      return {
        ...state,
        addresses: currentAddresses,
      };
    }

    default:
      return state;
  }
}
