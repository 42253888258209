import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import DescriptionIcon from '@material-ui/icons/Receipt';
import PhoneIcon from '@material-ui/icons/Phone';
import InstagramIcon from '@material-ui/icons/Instagram';
import { Box, Grid } from '@material-ui/core';
import Logo from '../../assets/logo.png';
import useStyles from './styles';

export default function Menu() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { modalMenu, user } = useSelector(state => state.data);

  const handleClose = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    dispatch({ type: '@data/MENU' });
  };

  return user ? (
    <Drawer anchor="left" open={modalMenu} onClose={handleClose}>
      <div
        className={classes.screen}
        role="presentation"
        onClick={handleClose}
        onKeyDown={handleClose}
      >
        <List className={classes.List}>
          <ListItem className={classes.ListItem}>
            <Avatar className={classes.Avatar} src={user.url} alt={user.name} />

            <div className={classes.user}>
              <Typography variant="button" display="block" gutterBottom>
                {user.name}
              </Typography>

              <Typography className={classes.userEmail}>
                {user.email}
              </Typography>
            </div>
          </ListItem>
        </List>
        <Divider />
        {/* <List>
          <ListItem button>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Pedidos" />
          </ListItem>
        </List> */}
        <Box
          p={2}
          style={{ position: 'fixed', bottom: '0', left: '0', width: '296px' }}
        >
          <Divider />
          <Grid
            style={{ paddingTop: '16px' }}
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid
              item
              xs
              container
              direction="column"
              justify="flex-start"
              alignContent="center"
              alignItems="center"
            >
              <Avatar className={classes.Avatar} src={Logo} alt="gestor food" />
              <Typography
                style={{
                  fontSize: '14px',
                  padding: '5px',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                gestor food
              </Typography>
            </Grid>
            <Grid item xs container direction="row" alignContent="center">
              <Grid
                item
                xs={7}
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                alignItems="center"
              >
                <PhoneIcon />
                <Typography
                  style={{
                    fontSize: '14px',
                    marginLeft: '2px',
                    fontWeight: 'bold',
                  }}
                >
                  (65)99350-6792
                </Typography>
              </Grid>
              <Grid
                item
                xs
                container
                direction="row"
                justify="flex-start"
                alignContent="center"
                alignItems="center"
              >
                <InstagramIcon />
                <Typography
                  style={{
                    fontSize: '14px',
                    marginLeft: '2px',
                    fontWeight: 'bold',
                  }}
                >
                  @gestorfood
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Drawer>
  ) : null;
}
