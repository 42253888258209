import React from 'react';

import ProductWithoutImage from '../../../../../assets/ProductWithoutImage.png';
import useStyles from './styles';

function Item({ item, handleClickItem }) {
  const classes = useStyles();

  return (
    <button
      type="button"
      className={classes.root}
      onClick={() => handleClickItem(item)}
    >
      <div className={classes.fields}>
        <span className={classes.name}>{item.name.toLowerCase()}</span>
        <span className={classes.description}>{item.description}</span>
        {item.price !== 0.01 && (
          <span className={classes.price}>{item.formattedPrice}</span>
        )}
      </div>
      <div
        className={classes.media}
        style={{
          backgroundImage: `url(${item.url ? item.url : ProductWithoutImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      />
    </button>
  );
}

export default Item;
