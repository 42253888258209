export default function item(
  state = {
    product: null,
    modalDetail: false,
    combined: null,
    modalCombined: false,
  },
  action
) {
  switch (action.type) {
    case '@item/SELECTED': {
      const { product } = action.data;

      if (product) {
        return {
          ...state,
          product: {
            ...product,
            key: product.key || new Date().getTime(),
            amount: product.amount || 1,
            comment: product.comment || '',
            complements: product.complements.map(complement => {
              return {
                ...complement,
                selected: complement.selected || 0,
                items: complement.items.map(item => {
                  return {
                    ...item,
                    amount: item.amount || 0,
                  };
                }),
              };
            }),
          },
          modalDetail: true,
        };
      }

      return state;
    }

    case '@item/COMPLEMENT_ADD': {
      const { product } = action.data;
      if (product) {
        const comple = state.product.complements.find(
          f => f.complement_id === product.complement_id
        );

        if (comple.selected === comple.max) return state;

        return {
          ...state,
          product: {
            ...state.product,
            complements: state.product.complements.map(complement => {
              return {
                ...complement,
                selected:
                  complement.complement_id === product.complement_id
                    ? complement.selected + 1
                    : complement.selected,
                items: complement.items.map(item => {
                  return {
                    ...item,
                    amount:
                      item.item_id === product.item_id
                        ? item.amount + 1
                        : item.amount,
                  };
                }),
              };
            }),
          },
        };
      }
      return state;
    }

    case '@item/COMPLEMENT_REMOVE': {
      const { product } = action.data;

      if (product) {
        const comple = state.product.complements.find(
          f => f.complement_id === product.complement_id
        );

        if (comple.selected <= 0) return state;

        return {
          ...state,
          product: {
            ...state.product,
            complements: state.product.complements.map(complement => {
              return {
                ...complement,
                selected:
                  complement.complement_id === product.complement_id
                    ? complement.selected - 1
                    : complement.selected,
                items: complement.items.map(item => {
                  return {
                    ...item,
                    amount:
                      item.item_id === product.item_id
                        ? item.amount - 1
                        : item.amount,
                  };
                }),
              };
            }),
          },
        };
      }
      return state;
    }

    case '@item/CANCEL': {
      return {
        product: null,
        modalDetail: false,
        combined: null,
        modalCombined: false,
      };
    }

    default:
      return state;
  }
}
