import React from 'react';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Store from './pages/Store';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/:token" component={Store} />
        <Route exact path="/" component={Store} />
      </Switch>
    </BrowserRouter>
  );
}
