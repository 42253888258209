import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    minHeight: '100vh',
    padding: 0,

    display: 'flex',
    flexDirection: 'column',
  },
  splash: {
    height: '100vh',
    padding: 0,
    backgroundColor: '#040708',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatar: {
    width: '80%',
    height: 'auto',
    borderRadius: 0,
  },
}));

export default useStyles;
