export default function data(
  state = {
    loading: true,
    modalMenu: false,
    token: '',
    company: {},
    categories: [],
    kms: [],
    client: { name: '', phone: '' },
    districts: [],
  },
  action
) {
  switch (action.type) {
    case '@data/LOAD': {
      const { token } = action.data;
      const storageClient = localStorage.getItem(`client-${token}`);
      const startClient = storageClient
        ? JSON.parse(storageClient)
        : { name: '', phone: '' };

      return {
        ...state,
        ...action.data,
        client: startClient,
        loading: false,
      };
    }

    case '@data/SET_CLIENT': {
      localStorage.setItem(
        `client-${state.token}`,
        JSON.stringify(action.client)
      );

      return {
        ...state,
        client: action.client,
      };
    }

    case '@data/LOADING': {
      return {
        ...state,
        loading: !state.loading,
      };
    }

    case '@data/MENU': {
      return {
        ...state,
        modalMenu: !state.modalMenu,
      };
    }

    default:
      return state;
  }
}
